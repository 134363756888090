import React, { Fragment } from 'react'
import { Periods } from '@commonstock/common/src/types'
import { useGetTopInvestors, useGetFollowerLeaderboard } from '@commonstock/common/src/api/asset'
import { useGetSuggestedUsers, useGetTopAuthors } from '@commonstock/common/src/api/recommendation'
import { SpacerH, SpacerV } from '../../composing/Spacing'
import ListRowHeader from '../../components/ListRowHeader'
import UserBlock from '../../components/UserBlock'
import { SectionCard } from '../../components/styles'
import { Badge } from '../../composing/Badge'
import { FlexColumn, FlexRow } from '../../composing/Flex'
import { TextCaption, TextCaptionEmphasis, TextBodyEmphasis } from '../../composing/CSText'
import { gradientText } from '../../theme/AtomicClasses'
import { Colors } from '../../theme/constants'
import { formatLargeNumbers, formatLargeMoney, formatPercentSigned } from '@commonstock/common/src/utils/format'
import {
  DiscoverBestContentSkeleton,
  DiscoverInvestorsGainingPopularitySkeleton,
  DiscoverTopPerformersSkeleton,
  DiscoverInvestorsToFollowSkeleton
} from './Discover.skeleton'
import InvestorListItem from '../../components/InvestorListItem'
import FollowProfile from '../profile/FollowProfile'
import CardCarousel from '../../components/CardCarousel'
import { SkeletonWrapper } from '../../components/SuspenseSkeleton'
import { useTheme } from 'src/theme/ThemeContext'

export function DiscoverTopPerformingInvestors() {
  const [topInvestors, , topInvestorsPending] = useGetTopInvestors({
    query: { period: Periods['1W'], limit: 10, calc_type: 'simple' }
  })
  return (
    <SkeletonWrapper
      pending={!!topInvestorsPending}
      skeleton={<DiscoverTopPerformersSkeleton />}
      failed={!topInvestors?.length}
    >
      {topInvestors && topInvestors.length > 0 && (
        <CardCarousel
          title={'Top performers this week'}
          showSliderButtons={topInvestors.length > 3}
          height={8.625}
          topSlidesSpacer={1.125}
          bottomSlidesSpacer={1.0625}
        >
          {topInvestors.map((user, index) => (
            <InvestorListItem
              key={index}
              user={user.user}
              badge={
                <Badge
                  ellipsis
                  gain={user.period_return > 0}
                  loss={user.period_return < 0}
                  value={formatPercentSigned(user.period_return) || ''}
                />
              }
            />
          ))}
        </CardCarousel>
      )}
    </SkeletonWrapper>
  )
}

export function DiscoverInvestorsWritingBestContent() {
  const [topAuthors, , topAuthorsPending] = useGetTopAuthors({ query: { period: Periods['1W'], limit: 10 } })
  const usersHaveLikeValue = topAuthors?.some(u => u.total_like_value)

  return (
    <SkeletonWrapper
      pending={!!topAuthorsPending}
      skeleton={<DiscoverBestContentSkeleton />}
      failed={topAuthors && !topAuthors.length}
    >
      {topAuthors && (
        <CardCarousel
          title={'Investors writing the best content'}
          showSliderButtons={topAuthors.length > 3}
          height={usersHaveLikeValue ? 8.625 : 6.75}
          topSlidesSpacer={1.125}
          bottomSlidesSpacer={1.0625}
        >
          {topAuthors.map((user, index) => (
            <InvestorListItem
              key={index}
              user={user}
              badge={
                user.total_like_value ? (
                  <Badge ellipsis gradient value={formatLargeMoney(user.total_like_value)} label="Likes" />
                ) : (
                  undefined
                )
              }
            />
          ))}
        </CardCarousel>
      )}
    </SkeletonWrapper>
  )
}

export function DiscoverInvestorsToFollow() {
  const [investors, , investorsPending] = useGetSuggestedUsers({ query: { limit: 10 } })

  return (
    <SkeletonWrapper
      pending={!!investorsPending}
      skeleton={<DiscoverInvestorsToFollowSkeleton />}
      failed={investors && !investors.length}
    >
      {investors && (
        <CardCarousel
          title={'Investors to follow'}
          showSliderButtons={investors.length > 3}
          height={9.25}
          topSlidesSpacer={1.0625}
          bottomSlidesSpacer={1.0625}
        >
          {investors.map((user, index) => (
            <InvestorListItem key={index} user={user} button={<FollowProfile cardItem profile={user} />} />
          ))}
        </CardCarousel>
      )}
    </SkeletonWrapper>
  )
}

export function DiscoverInvestorsGainingPopularity() {
  const [topFollowers, , topFollowersPending] = useGetFollowerLeaderboard({
    query: { period: Periods['1W'], limit: 4 }
  })
  const { isMobile } = useTheme()

  return (
    <SkeletonWrapper
      pending={!!topFollowersPending}
      skeleton={<DiscoverInvestorsGainingPopularitySkeleton />}
      failed={topFollowers && !topFollowers.users.length}
    >
      {topFollowers && (
        <SectionCard>
          <ListRowHeader title="Investors gaining in popularity" />
          <SpacerV rem={0.375} />
          {topFollowers.users.map((user, index) => (
            <Fragment key={index}>
              <SpacerV rem={1.125} />
              <FlexRow alignCenter>
                <TextCaption>{index + 1}</TextCaption>
                <SpacerH />
                <UserBlock user={user.user} disableTooltips />
                {isMobile ? (
                  <FlexColumn alignEnd>
                    <TextCaptionEmphasis block className={gradientText}>
                      ${formatLargeNumbers(user.rank.follower_value)}
                    </TextCaptionEmphasis>
                    <TextCaption ellipsis color={Colors.TextSecondary}>
                      Follower assets
                    </TextCaption>
                  </FlexColumn>
                ) : (
                  <TextBodyEmphasis block className={gradientText}>
                    ${formatLargeNumbers(user.rank.follower_value)} follower assets
                  </TextBodyEmphasis>
                )}
              </FlexRow>
            </Fragment>
          ))}
        </SectionCard>
      )}
    </SkeletonWrapper>
  )
}
